import * as React from 'react';
import Card from '@mui/material/Card';
// import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
// import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { motion } from "framer-motion"
import Lottie from "react-lottie";

import recruitment from "../assets/recruitment.json";
import consultanvcy from "../assets/consultancy.json";
import solution from "../assets/solutiondesign.json";

import img1 from '../assets/card1.jpg';
import img2 from '../assets/card2.jpg';
import img3 from '../assets/card3.jpg';

const CustomCard = ({ title, description, animationData }) => {
  return (
    <motion.div
    initial={{ opacity: 0, scale: 0.5 }}
    animate={{ opacity: 1, scale: 1 }}
    transition={{
      duration: 0.8,
      delay: 0.5,
      ease: [0, 0.71, 0.2, 1.01]}}
    >
    <Card sx={{ maxWidth: 500, borderRadius:'15px', minHeight:'30rem' }} className="m-4 rounded-3xl">
      {/* <CardMedia
        component="img"
        alt="Card Image"
        height="140"
        width="300"
        image={imageUrl}
      /> */}
      <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: animationData,
          }}
          // height={140}
          width={200}
        />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div" style={{ color: '#635BB8',fontWeight:'bold' }}>
          {title}
        </Typography>
        <Typography variant="" color="" className='text-lg font-semibold text-gray-600 ' style={{ color: '#111827' }}>
          {description}
        </Typography>
      </CardContent>
      {/* <CardActions>
        <Button size="small">Learn More</Button>
      </CardActions> */}
    </Card>
    </motion.div>
  );
};

const ImgMediaCard = () => {
  return (
    <div className="flex sm:flex-row flex-col xs:flex-col items-center">
      <CustomCard
        title="Recruitment Process"
        description="We make the recruitment process more effective and efficient. We identify your needs, search for talent and find the right fit. Our step-by-step approach will benefit your company. The addition of talented people will make your company grow."
        // imageUrl={img1}
        animationData={recruitment}
      />
      <CustomCard
        title="Consultancy"
        description="One of our Business Development Managers will walk you through the recruiting process acting as a consultant, taking the time to understand your organization and get a clear overview of the enterprise, its organizational structure, values and goals."
        // imageUrl={img2}
        animationData={consultanvcy}
      />
      <CustomCard
        title="Solution Design"
        description="We offer a full suite of professional services to grow your business. Our goal is to design the right solution, no matter how simple or complex it may be. We are committed to your success."
        // imageUrl={img3}
        animationData={solution}
      />
    </div>
  );
};

export default ImgMediaCard;
