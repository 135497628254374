// contact.js
import React from "react";
import Contact from "../components/Contact";
import { Container } from "@mui/material";
import Banneranimation from "../components/Bannercontact";
import Cta2 from "../components/Cta2";

const contact = () => {
  return (
    <div>
      <Banneranimation />
      <Container>
        <section>
          <Contact />
        </section>
      </Container>
      <Container className="py-5">
        <Cta2 />
      </Container>
    </div>
  );
};

export default contact;
