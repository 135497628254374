import React from "react";
import { motion } from "framer-motion";
import Lottie from "react-lottie";
import animationData from "../assets/globaldesk04.json";
import animationDataMobile from "../assets/banner-mobile.json";
import "../App.css";

const Banneranimation = () => {
  return (
    <div>
    <motion.div
      initial={{ opacity: 0, x: -50 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.5 }}
      className="overflow-hidden w-full"
    >
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: animationData,
        }}
      />
    </motion.div>
  </div>
  );
};

export default Banneranimation;
