import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Grid, Button, Select, MenuItem, InputLabel } from '@mui/material';
import { Link } from 'react-router-dom'; // Importa Link de react-router-dom
import data from '../utils/busquedas.json';

const TarjetasComponent = () => {
  const [filtroFecha, setFiltroFecha] = useState('');
  const [filtroCategoria, setFiltroCategoria] = useState('');

  const handleFiltroFechaChange = (event) => {
    setFiltroFecha(event.target.value);
    setFiltroCategoria(''); // Limpiar el filtro de categoría al cambiar la fecha
  };

  const handleFiltroCategoriaChange = (event) => {
    setFiltroCategoria(event.target.value);
    setFiltroFecha(''); // Limpiar el filtro de fecha al cambiar la categoría
  };

  const filteredData = data.filter((item) => {
    return (
      (filtroFecha === '' || item.fecha === filtroFecha) &&
      (filtroCategoria === '' || item.categoria === filtroCategoria)
    );
  });

  return (
    <div className='py-20 align-middle'>
      <Grid container spacing={2} justifyContent="flex-end" className='py-5 align-middle' style={{ alignItems:'center' }}>
        <Grid item className='flex align-middle'>
        <InputLabel style={{ fontWeight:'800',paddingRight:'5px' }} className='px-3 text-gray-900'>Date</InputLabel>
          <Select value={filtroFecha} onChange={handleFiltroFechaChange} style={{ backgroundColor:'#C8C8D4',color:'white',padding:'10px', width:'40px', height:'25px' }}>
            <MenuItem value="">All dates</MenuItem>
            {/* Agrega opciones de fechas según tu lógica */}
          </Select>
        </Grid>
        <Grid item className='flex align-middle'>
        <InputLabel style={{ fontWeight:'800',paddingRight:'5px' }} className='px-3 text-gray-900'>Category</InputLabel>
          <Select value={filtroCategoria} onChange={handleFiltroCategoriaChange} style={{ backgroundColor:'#C8C8D4',color:'white',padding:'10px', width:'40px', height:'25px' }}>
            <MenuItem value="">All Categories</MenuItem>
            <MenuItem value="Categoría A">Frontend</MenuItem>
            <MenuItem value="Categoría B">Backend</MenuItem>
          </Select>
        </Grid>
        <Grid item>
          <Button variant="contained" onClick={() => { setFiltroFecha(''); setFiltroCategoria(''); }}
          className='bg-green-200'
          style={{ backgroundColor:'#777598' }}
          >
            Limpiar filtros
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        {filteredData.map((item, index) => (
          <Grid item key={item.id} xs={12} sm={6} md={4} lg={3}>
            {filtroCategoria === '' || item.categoria === filtroCategoria ? (
              <motion.div
                style={{ backgroundColor:'' }}
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{
                  duration: 0.8,
                  delay: index * 0.2,
                  ease: [0, 0.71, 0.2, 1.01]
                }}
                className='bg-green-200 rounded-xl p-5 shadow-sm min-h-96'
              >
                <div>
                  <div className='flex md:justify-center justify-start'>
                    <img src={item.imagen} alt={item.titulo} className='max-h-16'/>
                  </div>
                  <h3 className='font-black text-xl'>{item.titulo}</h3>
                  <p className='text-lg'>{item.texto}</p>
                  <p className='font-semibold text-violet-800'>{item.fecha}</p>
                  <p className='font-bold text-green-500'>{item.categoria}</p>
                  {/* Agrega el botón con Link */}
                  <Link to={`/jobseekers/${item.id}`}>
                    <div className='py-4'>
                    <Button variant="contained" className='py-10' style={{ backgroundColor:'#D89FFC', color:'#301C49', fontWeight:'600', textTransform: 'none' }}>
                      Apply for this job
                    </Button>
                    </div>
                  </Link>
                </div>
              </motion.div>
            ) : null}
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default TarjetasComponent;
