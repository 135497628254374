import {
  CommandLineIcon,
  UserPlusIcon,
  BriefcaseIcon,
} from "@heroicons/react/20/solid";
import { motion } from "framer-motion";

import imgFeature from "../assets/feature.jpg";

const features = [
  {
    name: "Business Focused",
    description:
      "We listen to you, understand your business’ needs and connect you with top talent to support your projects on a temporary or long-term basis, remote or on-site work.",
    icon: BriefcaseIcon,
  },
  {
    name: "Technology Inspired",
    description:
      "We will definitely help you make your projections come true. Tech Inspire is more than technology, it’s about players.",
    icon: CommandLineIcon,
  },
  {
    name: "Focus on You",
    description:
      "We are real people with real interests. We focus on creating IT solutions for your business according to your goals and ideas.   ",
    icon: UserPlusIcon,
  },
];

export default function Feacture() {
  return (
    <div className="overflow-hidden bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
              <h2 className="text-xl font-black leading-7" style={{ color: "#153933" }}>
                A little About Us
              </h2>
              <p className="mt-2 text-5xl font-black tracking-tight sm:text-4xl" style={{ color: "#3A337F" }}>
                Hire anyone, anytime, anywhere.
              </p>
              <p className="mt-6 text-xl font-bold leading-8 text-gray-600">
                The best people in tech just happen to be all over the world. If
                you see an opportunity that requires international talent, your
                competitors probably do, too. It’s a race.
              </p>
              <p className="mt-6 text-xl font-bold sleading-8 text-gray-600">
                We are a team of experienced and smart IT HR professionals. We
                have been working with the best companies around the world for
                over 20 years.
              </p>
              <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                {features.map((feature) => (
                  <div key={feature.name} className="relative pl-9">
                    <dt className="inline text-lg font-bold text-gray-900">
                      <feature.icon
                        className="absolute left-1 top-1 h-5 w-5"
                        aria-hidden="true"
                        style={{ color:"#3A337F" }}
                      />
                      {feature.name}
                    </dt>{" "}
                    <dd className="inline text-lg font-semibold">
                      {feature.description}
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
          <img
            src={imgFeature}
            alt="U&B STAFF"
            className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0 h-full object-cover"
            width={2432}
            height={1442}
          />
        </div>
      </div>
    </div>
  );
}
