import React from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import App from "./App";
import Terms from "./screens/Terms";
import Privacy from "./screens/Privacy";
import Jobseeker from "./screens/Jobseeker";
import Aboutus from "./screens/Aboutus";
import Services from "./screens/Services";
import Contact from "./screens/Contact";
import Howtoapply from "./screens/Howtoapply";
import Applynow from "./screens/Applynow";
import Topbar from "./conteiners/Topbar";
import Footer from "./conteiners/Footer";
import { Container } from "@mui/material";
import NotFound from "./screens/404";
import TemplatePage from "./screens/TemplatePage";
import { firebaseConfig } from "./firebaseConfig";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);

const Main = () => {
  return (
    <Router>
      <Helmet>
        <title>U&B Staff | Smart Hiring for IT Talent</title>
        <meta name="description" content="Unlock top Latin American IT talent with our specialized recruitment services. Streamlined remote hiring, transparent fees." />
        <link rel="canonical" href="https://ubstaff.us" />
        <meta
          name="keywords"
          content="IT talent, recruitment, Latin America, remote hiring, transparent fees, guaranteed satisfaction"
        />
        <meta name="robots" content="index, follow" />
        <meta
          property="og:title"
          content="U&B Staff | Smart Hiring for IT Talent"
        />
        <meta
          property="og:description"
          content="Unlock top Latin American IT talent with our specialized recruitment services. Streamlined remote hiring, transparent fees, guaranteed satisfaction. Explore our extensive network of skilled professionals today!"
        />
        <meta property="og:url" content="https://ubstaff.us" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://ubstaff.us/static/media/logo.3430dbd47366820e7b6f2d4635d0ead1.svg"
        />
      </Helmet>
      <Topbar />
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/aboutus" element={<Aboutus />} />
        <Route path="/services" element={<Services />} />
        <Route path="/howtoapply" element={<Howtoapply />} />
        <Route path="/applynow" element={<Applynow />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/jobseeker" element={<Jobseeker />} />
        <Route path="/privacy" element={<Privacy />} />

        <Route path="/jobseekers/:id" element={<TemplatePage />} />

        <Route path="*" element={<NotFound />} />
      </Routes>

      <section style={{ backgroundColor: "#1C4840" }}>
        <Container maxWidth="lg">
          <Footer />
        </Container>
      </section>
    </Router>
  );
};

export default Main;
