import React from 'react';
import { motion } from 'framer-motion';
import { dividerClasses } from '@mui/material';

const Values = () => {
  return (
   <div>
    <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.8, ease: 'easeInOut' }}
    >
    <h1 className='text-center font-black text-4xl'>Our Vision & Values</h1>
</motion.div>
<div className='flex py-10 flex-col-reverse md:flex-row'>
    <div className='md:px-10 px-2 pb-10'>
    <motion.div
        initial={{ opacity: 0, x: -50 }} 
        animate={{ opacity: 1, x: 0 }}  
        transition={{ duration: 0.8, ease: 'easeInOut' }} 
        className="flex-1 px-10 pb-10 rounded-2xl p-5 min-h-96"
        style={{ backgroundColor:'#FDD46F' }}
        >
        <h1 className="font-black text-4xl text-gray-900 mb-4 text-left">Our Vision</h1>
        <p className="text-xl font-semibold text-gray-900 mb-2 text-left">
        U&B Staff envisions a future where our recruitment solutions transcend boundaries, generating positive impacts on individuals and businesses. We are dedicated to serving as catalysts for positive change, shaping lives and businesses through our services.
        </p>
      </motion.div>

      </div>
      <div className='md:px-10 px-2 pb-10'>
            <motion.div
       initial={{ opacity: 0, x: 50 }} // Estado inicial: opacidad 0 y desplazamiento hacia la derecha (50 unidades)
       animate={{ opacity: 1, x: 0 }}  // Estado final: opacidad 1 y desplazamiento de regreso a la posición original
       transition={{ duration: 0.8, ease: 'easeInOut' }} // Configuración de la transición
        className="flex-1 px-10 pb-10 rounded-2xl p-5 min-h-96"
        style={{ backgroundColor:'#A3E0D1' }}
        >
        <h1 className="font-black text-4xl text-gray-900 mb-4 text-left">Our Values</h1>
        <p className="text-xl font-semibold text-gray-900 mb-2 text-left">
        Ethic, Integrity, Excellence: Upholding the highest standards in all interactions, ensuring trust and client satisfaction.

Commitment, Diversity, Passion: Dedicated to exceeding expectations, embracing diversity, and approaching every task with genuine enthusiasm.

At U&B Staff, these values guide our actions, defining our commitment to making a positive impact in the realm of recruitment.
        </p>
        
      </motion.div>
   </div>
   </div>
   </div>
  );
};

export default Values;
