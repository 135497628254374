import React from 'react';
import { motion } from 'framer-motion';

const Text = () => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8, ease: 'easeInOut' }}
      className="px-10 pb-10">
      <h1 className="font-black text-5xl mb-4 text-center" style={{ color:"#635BB8" }}>Recruitment is our core</h1>
      <p className="text-xl font-semibold text-gray-600 mb-2 text-center">
        We are committed to finding the best talent on the market to meet your business requirements. 
        Our IT Recruiters are continually connecting and building talent networks to meet the different needs of IT resources.
      </p>
      <p className="text-xl font-semibold text-gray-600 text-center">
        It is through this process that we are able to quickly endorse talent that has the required skills, 
        background, and experience for your company. We will work with you to provide the advice you need to maximize your business  goals.
      </p>
    </motion.div>
  );
};

export default Text;
