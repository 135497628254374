// services.js
import React from "react";
import Displaytext from "../components/Displaytext-services";
import Banner from "../components/Banner-services";
import Text from "../components/Text-services";
import Feacture from "../components/Feacture-services";
import Cta from "../components/Cta2";
import { Container } from "@mui/material";

const services = () => {
  return (
    <div>
      <Container>
        <section>
          <Text />
        </section>
      </Container>
      <Container>
        <section>
          <Feacture />
        </section>
      </Container>
      <Banner />
      <Container>
        <section className="py-10">
          <Cta />
        </section>
      </Container>
    </div>
  );
};

export default services;
