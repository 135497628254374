import React from 'react';
import { motion } from 'framer-motion';

const Text = () => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8, ease: 'easeInOut' }}
      className="px-10 py-10">
      <h1 className="font-black text-5xl mb-4 text-center" style={{ color:"#3A337F" }}>Services</h1>
      <p className="text-xl font-semibold text-gray-600 mb-2 text-center">
      At U&B Staff, we recognize the critical role that proper employee selection plays in the success of business organizations. Therefore, we place utmost importance on ensuring that the selection process is carried out meticulously and tailored to meet the unique needs and peculiarities of each company.
      </p>
      <p className="text-xl font-semibold text-gray-600 text-center">
      Our services are carefully adapted to match the profile of the candidates you seek and the specific requirements of your organization. We understand that every business is different, which is why we offer personalized recruitment solutions designed to address your company's unique challenges and goals.
      </p>
    </motion.div>
  );
};

export default Text;
