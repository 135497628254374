// TemplatePage.tsx
import React from 'react';
import { useParams } from 'react-router-dom';
import data from '../utils/busquedas.json';
import Aplicationform from '../components/Aplicationform';
import { Container } from '@mui/material';





const TemplatePage = () => {
  const { id } = useParams(); // Obtén el parámetro id de la URL

  // Busca el trabajo correspondiente en el conjunto de datos
  const job = data.find((item) => item.id.toString() === id);

  // Verifica si se encontró el trabajo
  if (!job) {
    return <div>No se encontró el trabajo.</div>;
  }
  console.log('id job:', { id });

  
  console.log('titulo:', { title: job.titulo }); // Usar el título del trabajo actual


const jobid = {id};

  return (
    <div>
        <section className='flex flex-col pt-20 md:px-50 px-5' >
            <div className='flex flex-col font-bold items-center py-5'>
                <p className='text-violet-800'>Fecha: {job.fecha}</p>
                <p className='text-violet-500'>Categoría: {job.categoria}</p>
            </div>

            <div className='flex flex-col text-center items-center py-5 '>
                <img src={job.imagen} alt={job.titulo} className='w-20'/>
            </div>
                <h1 className='font-black text-center text-4xl py-2 text-gray-800'>{job.titulo}</h1>
            <div className='text-center md:px-20'>
                <p className='pb-5 font-bold text-base text-gray-800'>{job.texto}</p>
                <p className='text-lg font-semibold '>{job.content}</p>
            </div>
                {/* Agrega más detalles según sea necesario */}
        </section>
        <Container>
        <Aplicationform jobId={job.titulo} />
        </Container>
    </div>
  );
};

export default TemplatePage;
