import React, { useState, useRef } from 'react';
import { motion } from 'framer-motion';
import emailjs from 'emailjs-com';
import Modal from 'react-modal';
import img from '../assets/contact.jpg';
import '../App.css';


import Lottie from 'react-lottie';
import animationData from '../assets/email-animation.json';

Modal.setAppElement('#root');

const Contact = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
    company: '',
    website: '',
    linkedin: '',
    message: '',
  });

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const formRef = useRef();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validations
    if (
      formData.fullName.trim() === '' ||
      formData.email.trim() === '' ||
      formData.phone.trim() === '' ||
      formData.company.trim() === '' ||
      formData.website.trim() === '' ||
      formData.linkedin.trim() === '' ||
      formData.message.trim() === ''
    ) {
      setErrorMessage('Please fill in all fields');
      setTimeout(() => {
        setErrorMessage('');
      }, 3000);
      return;
    }

    if (!formData.email.includes('@')) {
      setErrorMessage('Please enter a valid email address');
      setTimeout(() => {
        setErrorMessage('');
      }, 3000);
      return;
    }

    const emailServiceId = 'service_68rj82a';
    const emailTemplateId = 'template_1t37y09';
    const userId = 'gq64XCT6baJ9iQDKm';

    emailjs
      .sendForm(emailServiceId, emailTemplateId, formRef.current, userId)
      .then((response) => {
        console.log('Correo electrónico enviado:', response);
        setFormSubmitted(true);
        setIsModalOpen(true);
        // Limpia los datos del formulario después de enviar el mensaje
        setFormData({
          fullName: '',
          email: '',
          phone: '',
          company: '',
          website: '',
          linkedin: '',
          message: '',
        });
      })
      .catch((error) => {
        console.error('Error al enviar el correo electrónico:', error);
        setErrorMessage('Error sending the message. Please try again.');
        setTimeout(() => {
          setErrorMessage('');
        }, 3000);
      });
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setFormSubmitted(false);
    // Agrega la redirección a la página de inicio al cerrar el modal
    // Por ejemplo, usando react-router
    // Puedes ajustar según tu enrutador o preferencia.
    // Si no estás utilizando un enrutador, puedes usar window.location.href
    window.location.href = '/'; // Cambia '/' por la ruta de tu página de inicio
  };

  return (
    <div className="flex flex-col items-center justify-center mt-0   pb-10">
      <div className="flex flex-col items-center sm:flex-row">
        <motion.div
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
          className={`order-2 sm:order-1 ${formSubmitted ? 'hidden' : ''}`}
        >
          <img src={img} alt="Contact Image" className="w-96 hidden md:flex rounded-xl" />
        </motion.div>

        <div className={`mt-8 sm:ml-8 sm:mt-0 order-1 sm:order-2 ${formSubmitted ? 'hidden' : ''}`}>

            
          <p className="text-4xl font-black mb-4 pt-5" style={{ color:'#301C49' }}>Get in Touch</p>
          <p className="font-bold text-xl mb-6" style={{ color:'#301C49' }}>
            Any questions or remarks? 
            <br />
            We'll get back to you within 24 hours.
          </p>
          <div id="contact">
            <form ref={formRef} onSubmit={handleSubmit}>
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                <div>
                  <label htmlFor="fullName" className="block text-base font-bold " style={{ color:'#003A33'}}>
                    Full Name
                  </label>
                  <input
                    type="text"
                    id="name_id"
                    name="fullName"
                    value={formData.fullName}
                    onChange={handleChange}
                    placeholder="Kevin"
                    className="mt-1 p-2 w-full border border-violet-500 rounded-md focus:outline-none focus:border-blue-500"
                    style={{ fontWeight:'800', color:'#301C49' }}
                  />
                </div>

                <div>
                  <label htmlFor="email" className="block text-base font-bold " style={{ color:'#003A33' }}>
                    Email Address
                  </label>
                  <input
                    type="email"
                    id="email_id"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Davis"
                    className="mt-1 p-2 border w-full border-violet-500 rounded-md focus:outline-none focus:border-blue-500"
                    style={{ fontWeight:'800', color:'#301C49' }}
                  />
                </div>

                <div>
                  <label htmlFor="phone" className="block text-base font-bold " style={{ color:'#003A33'}}>
                    Phone Number
                  </label>
                  <input
                    type="tel"
                    id="phone_id"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    pattern="[0-9]*"  // Patrón para permitir solo números
                    maxLength="12"
                    placeholder="+17187172890"
                    className="mt-1 p-2 w-full border border-violet-500 rounded-md focus:outline-none focus:border-blue-500"
                    style={{ fontWeight:'800', color:'#301C49' }}
                  />
                </div>

                <div>
                  <label htmlFor="company" className="block text-base font-bold " style={{ color:'#003A33' }}>
                    Company Name
                  </label>
                  <input
                    type="company"
                    id="company_id"
                    name="company"
                    value={formData.company}
                    onChange={handleChange}
                    placeholder="U&B Staff"
                    className="mt-1 p-2 border w-full border-violet-500 rounded-md focus:outline-none focus:border-blue-500"
                    style={{ fontWeight:'800', color:'#301C49' }}
                  />
                </div>

                <div className="col-span-2">
                  <label htmlFor="website" className="block text-base font-bold " style={{ color:'#003A33' }}>
                    Company Website
                  </label>
                  <input
                    type="text"
                    id="website_id"
                    name="website"
                    value={formData.website}
                    placeholder="https://ubstaff.us"
                    onChange={handleChange}
                    className="mt-1 p-2 w-full border border-violet-500 rounded-md focus:outline-none focus:border-blue-500"
                    style={{ fontWeight:'800', color:'#301C49' }}
                  />
                </div>

                <div className="col-span-2">
                  <label htmlFor="linkedin" className="block text-base font-bold " style={{ color:'#003A33' }}>
                    Linkedin URL
                  </label>
                  <input
                    type="text"
                    id="linkedin_id"
                    name="linkedin"
                    value={formData.linkedin}
                    placeholder="https://www.linkedin.com/company/u-b-staff/"
                    onChange={handleChange}
                    className="mt-1 p-2 w-full border border-violet-500 rounded-md focus:outline-none focus:border-blue-500"
                    style={{ fontWeight:'800', color:'#301C49' }}
                  />
                </div>

                <div className="col-span-2">
                  <label htmlFor="message" className="block text-base font-bold " style={{ color:'#003A33' }}>
                    Enter your message
                  </label>
                  <textarea
                    id="message_id"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    rows="4"
                    placeholder="Type here..."
                    className="mt-1 p-2 w-full border border-violet-500 rounded-md focus:outline-none focus:border-blue-500"
                    style={{ fontWeight:'800', color:'#301C49' }}
                  ></textarea>
                </div>

                <div className="col-span-2">
                  <button
                    type="submit"
                    className="w-full text-white p-2 rounded-md botoncontact transition duration-300"
                    style={{ fontWeight:'800', color:'#F58033' }}
                  >
                    Send Message
                  </button>
                </div>
              </div>
              {errorMessage && (
                <div className="bg-red-200 text-red-600 text-black p-2 rounded-xl py-5 text-center text-xl my-5" 
                style={{ fontWeight:'800' }}
                >
                  {errorMessage}
                </div>
              )}
            </form>
          </div>
        </div>

        </div>

{/* Modal */}
<div>
  <motion.div
    isOpen={isModalOpen}
    onRequestClose={closeModal}
    contentLabel="Message Sent Modal"
    className={`modal ${formSubmitted ? 'block' : 'hidden'}`}
    overlayClassName={`modal-overlay ${formSubmitted ? 'block' : 'hidden'}`}
  >
    <div className="modal-conten bg-green-200 p-10 rounded-xl text-center flex flex-col items-center">
      <div style={{ width: '100px', height: '100px' }}>
        <Lottie options={{ loop: true, autoplay: true, animationData }} />
      </div>
      <h2 className="text-green-500 font-black font-2xl">Message Sent Successfully!</h2>
      <p className="text-gray-600 font-black font-2xl">Your message has been sent. Thank you!</p>
      <button
        onClick={closeModal}
        className="text-white p-2 px-5 rounded-md font-black mt-4 boton-contact-form hover:bg-violet-600 bg-violet-400"
        style={{ }}
      >
        Close
      </button>
    </div>
  </motion.div>
</div>
</div>

);
};

export default Contact;
