import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import '../App.css'

const AccordionItem = ({ id, title, content, isOpen, toggleAccordion }) => {
  return (
    <div className="mb-1 md:px-0 px-10">
      <motion.div
        className="border rounded-md overflow-hidden relative"
        initial={false}
        animate={{ borderRadius: isOpen ? 0 : '1rem' }}
      >
        <button className="w-full p-3 text-left bg-white hovertext relative" onClick={() => toggleAccordion(id)}>
          {title}
          <span className={`absolute right-4 top-1/2 transform -translate-y-1/2 ${isOpen ? 'rotate-180' : ''}`}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
              className="h-4 w-4">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                d="M19 9l-7 7-7-7"></path>
            </svg>
          </span>
        </button>
        <AnimatePresence>
          {isOpen && (
            <motion.div
              key="content"
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              className="p-4 bg-white"
            >
              {content}
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    </div>
  );
};

const Faq = () => {
  const [openItem, setOpenItem] = useState(null);

  const toggleAccordion = (id) => {
    setOpenItem((prevOpenItem) => (prevOpenItem === id ? null : id));
  };

  const faqData = [
    {
      id: 1,
      title: "What makes you unique?",
      content: "We have a vast network and deep understanding of IT, ML, IA. Our rigorous screening ensures top talent for long-term success."
    },
    {
        id: 2,
        title: "How do you find candidates?",
        content: "We use a multi-faceted approach, leveraging our network, active recruitment, and partnerships."
      },
      {
        id: 3,
        title: "Can you share success stories?",
        content: "Absolutely! We've filled diverse IT roles, ensuring client satisfaction."
      },
      {
        id: 4,
        title: "How do you ensure quality?",
        content: "We conduct thorough assessments and provide ongoing support."
      },
      {
        id: 5,
        title: "How do you match candidates to us?",
        content: "We tailor our process to your culture and requirements."
      },
      {
        id: 6,
        title: "Do you offer guarantees?",
        content: "Yes, we guarantee satisfaction and offer replacements if needed."
      },
      {
        id: 7,
        title: "How do you handle remote hiring?",
        content: "We streamline communication and logistics for remote collaboration."
      },
      {
        id: 8,
        title: "What about fees and payment?",
        content: "Our fees are transparent and flexible, with various pricing models."
      }
];


    // Agrega más preguntas y respuestas según sea necesario
  return (
    <div className="">
        <h2 style={{ color:'#FAEEDD' }} className='text-4xl text-center font-bold pt-10'>Frequently Asked Questions</h2>
      <div className='pt-10 max-w-2xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-4'>
        {faqData.map((item) => (
          <div key={item.id} className='font-bold text-lg' >
            <AccordionItem
              id={item.id}
              title={item.title}
              content={item.content}
              isOpen={openItem === item.id}
              toggleAccordion={toggleAccordion}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Faq;
