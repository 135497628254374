// howtoapply.js
import React from 'react';
import Applytext from '../components/Applytext'
import Steps from '../components/Steps'
import { Container } from '@mui/material';
import Cta from '../components/Cta';
import Banner from '../components/Banner-how';

const howtoapply = () => {
  return (
    <div>
      <section style={{ backgroundColor:'#D89FFC' }}>
      <Container>
        <Applytext/>
      </Container>
      <Banner />
      </section>
      <section>
        <Container className='py-10'>
          <Steps/>
        </Container>
      </section>
      <Container className='py-10'>
        <Cta/>
      </Container>
    </div>    
  );
};

export default howtoapply;
