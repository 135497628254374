import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import '../App.css';
import img from '../assets/banner-cta.svg';

const CTA = () => {
  return (
    <motion.div
      className="relative rounded-[20px] overflow-hidden bg-cover bg-center h-64"
      style={{ backgroundImage: `url(${img})` }} 
      // style={{ backgroundColor: `#1C4840` }} 
    >
      <div className="absolute inset-0"></div>
      <div className="absolute inset-0 flex items-center justify-center md:px-20 px-5">
        <div className="text-center text-neutral-50">
          <h2 className="md:text-4xl text-2xl font-black mb-5">Unlock Success with Tailored 
          <br />
          Employee Selection Solutions</h2>
          {/* <p className="mb-4 md:text-xl text-base">
            Crafting a successful team begins with <span className='font-bold' style={{ color:'#A3E0D1' }}>smart hiring.</span> Let us find your
            <br />
            <span className='font-bold' style={{ color:'#A3E0D1' }}>ideal candidates</span> tailored to your <span className='font-bold' style={{ color:'#A3E0D1' }}>company's needs.</span>
          </p> */}
          <Link to='https://calendly.com/nora-ubstaff/u-b-staff-it-recruit-latam' target="_blank" className="font-black text-sm md:text-xl py-2 px-4 rounded-full boton-cta" style={{ backgroundColor:'' }}>
          Schedule a meeting
          </Link> 
        </div>
      </div>
    </motion.div>
  );
};

export default CTA;
