import React from 'react';
import { motion } from 'framer-motion';
import img1 from '../assets/aboutus.jpg';

const Aboutus = () => {
  return (
    <div className="flex py-10 flex-col-reverse md:flex-row">
      {/* Columna izquierda con texto */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, ease: 'easeInOut' }}
        className="flex-1 px-10 pb-10">
        <h1 className="font-black text-4xl text-gray-900 mb-4 text-left">Our Mission at U&B Staff</h1>
        <p className="text-xl font-semibold text-gray-900 mb-2 text-left">
Efficiently matching the right talent with the perfect job is our mission at U&B Staff. We go beyond expectations, striving to deliver more than anticipated. Our focus is on creating seamless connections that recognize the transformative power of a well-matched workforce.

        </p>
        <p className="text-xl font-semibold text-gray-900 text-left">
          It is through this process that we are able to quickly endorse talent that has the required skills, 
          background, and experience for your company. We will work with you to provide the advice you need to maximize your business goals.
        </p>
      </motion.div>

      {/* Columna derecha con la imagen */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, ease: 'easeInOut' }}
        className="flex-1 px-10 pb-10">
        <img src={img1} alt="Card" className="w-full h-auto rounded-2xl" />
      </motion.div>
    </div>

    
  );
};

export default Aboutus;
