import {
  UsersIcon,
  AcademicCapIcon,
  GlobeAmericasIcon,
} from "@heroicons/react/20/solid";
import { motion } from "framer-motion";

import imgFeature from "../assets/feature-services.jpg";

const features = [
  {
    name: "Comprehensive Candidate Identification",
    description:
      "We specialize in identifying, contacting, and motivating target candidates who possess the skills, experience, and cultural fit necessary to thrive within your organization. Our thorough approach ensures that you have access to a diverse pool of top-tier talent across LATAM.",
    icon: AcademicCapIcon,
  },
  {
    name: "Global Reach, Local Expertise",
    description:
      "With a focus on IT talent across LATAM, U&B Staff connects businesses worldwide with exceptional professionals. Our global network and deep understanding of regional nuances allow us to provide unparalleled service and support throughout the recruitment process.",
    icon: GlobeAmericasIcon,
  },
  {
    name: "Client-Centric Approach",
    description:
      "At U&B Staff, our clients and network of contacts are our constant motivation. We prioritize your satisfaction and success, working tirelessly to exceed your expectations and deliver exceptional results. Your success is our success.",
    icon: UsersIcon,
  },
];

export default function Feacture() {
  return (
    <div className="overflow-hidden">
      <div className="mx-auto max-w-7xl px-6 lg:px-8 pb-10">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
              <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                {features.map((feature) => (
                  <div key={feature.name} className="relative pl-9">
                    <dt className="inline text-lg font-bold text-gray-900">
                      <feature.icon
                        className="absolute left-1 top-1 h-5 w-5"
                        aria-hidden="true"
                        style={{ color:"#3A337F" }}
                      />
                      {feature.name}
                    </dt>{" "}
                    <dd className="inline text-lg font-semibold">
                      {feature.description}
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
          <img
            src={imgFeature}
            alt="U&B STAFF"
            className="rounded-xl shadow-xl object-cover"
            // width={200}
            // height={200}
          />
        </div>
      </div>
    </div>
  );
}
